<template>
  <div>
    <b-card
      title="Malzeme Ekle"
    >
      <validation-observer ref="simpleRules">
        <product-item-add-form />
      </validation-observer>
    </b-card>
    <b-row>
      <b-col>
        <b-button
          variant="primary"
          :disabled="dataItem.submitData"
          @click="submitData"
        >
          Kaydet
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BButton,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ProductItemAddForm from '@/views/Admin/Products/Components/ProductItemForm.vue'

export default {
  name: 'ProductItemAdd',
  components: {
    ProductItemAddForm,
    BCol,
    BCard,
    BRow,
    BButton,
    ValidationObserver,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['productItems/dataItem']
    },
    productData() {
      return this.$store.getters['products/dataItem']
    },
    saveData() {
      return this.$store.getters['productItems/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.dataItem.submitStatus = false
        this.$router.push(`/app/products/view/${this.$route.params.id}`)
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  created() {
    this.getProduct()
    this.$store.commit('productItems/RESET_DATA_ITEM')
  },
  methods: {
    getProduct() {
      this.$store.dispatch('products/getDataItem', this.$route.params.id)
    },
    submitData() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.dataItem.submitStatus = true
          if (this.productData) {
            this.dataItem.id_products = this.productData.id
          } else {
            this.dataItem.id_products = this.$route.params.id
          }
          this.$store.dispatch('productItems/saveData', this.dataItem)
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
