<template>
  <div>
    <b-row>
      <b-col cols="6">
        <b-form-group
          label="Stok Grubu"
          label-for="id_stock_groups"
        >
          <validation-provider
            #default="{ errors }"
            name="Stok Grubu"
            rules="required"
          >
            <v-select
              id="id_stock_groups"
              v-model="dataItem.id_stock_groups"
              :options="stockGroups"
              label="title"
              :reduce="item => item.id"
              placeholder="Seçiniz"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Stok Kartı"
          label-for="id_stocks"
        >
          <validation-provider
            #default="{ errors }"
            name="Stok Kartı"
            rules="required"
          >
            <v-select
              id="id_stocks"
              v-model="dataItem.id_stocks"
              :options="stocks"
              label="title"
              :reduce="item => item.id"
              placeholder="Seçiniz"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="En Düşük Kullanılan Miktar"
          label-for="min_amount"
        >
          <validation-provider
            #default="{ errors }"
            name="En Düşük Kullanılan Miktar"
            rules="required"
          >
            <b-input-group :append="stockData.units">
              <cleave
                id="min_amount"
                v-model="dataItem.min_amount"
                class="form-control"
                :raw="true"
                :options="options"
                placeholder="Giriniz"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="En Yüksek Kullanılan Miktar"
          label-for="max_amount"
        >
          <validation-provider
            #default="{ errors }"
            name="En Yüksek Kullanılan Miktar"
            rules="required"
          >
            <b-input-group :append="stockData.units">
              <cleave
                id="max_amount"
                v-model="dataItem.max_amount"
                class="form-control"
                :raw="true"
                :options="options"
                placeholder="Giriniz"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Notlar"
          label-for="notes"
        >
          <b-form-textarea
            id="notes"
            v-model="dataItem.notes"
            placeholder="Giriniz"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import Cleave from 'vue-cleave-component'

import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup, BRow, BCol, BInputGroup, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'ProductItemAddForm',
  components: {
    BInputGroup,
    vSelect,
    BFormGroup,
    BFormTextarea,
    ValidationProvider,
    BRow,
    BCol,
    Cleave,
  },
  data() {
    return {
      id_stocks: null,
      id_stock_groups: null,
      options: {
        numeral: true,
        numeralDecimalMark: ',',
        delimiter: '.',
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['productItems/dataItem']
    },
    stocks() {
      return this.$store.getters['stocks/dataList']
    },
    stockGroups() {
      return this.$store.getters['stockGroups/dataList']
    },
    stockData() {
      return this.$store.getters['stocks/dataItem']
    },
  },
  watch: {
    dataItem: {
      deep: true,
      handler(val) {
        if (val.id_stocks) {
          if (this.id_stocks !== val.id_stocks) {
            this.getStock()
            this.id_stocks = val.id_stocks
          }
        }
        if (val.id_stock_groups !== this.id_stock_groups) {
          this.getStocks(val.id_stock_groups)
          this.id_stock_groups = val.id_stock_groups
        }
      },
    },
  },
  created() {
    localize('tr')
    // this.getStocks()
    this.getStockGroups()
  },
  methods: {
    getStocks(stockGroup) {
      this.$store.dispatch('stocks/getDataList', {
        where: {
          'stocks.id_stock_groups': stockGroup,
        },
      })
    },
    getStockGroups() {
      this.$store.dispatch('stockGroups/getDataList')
    },
    getStock() {
      this.$store.dispatch('stocks/getDataItem', this.dataItem.id_stocks)
    },
  },
}
</script>
