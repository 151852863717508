var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Stok Grubu","label-for":"id_stock_groups"}},[_c('validation-provider',{attrs:{"name":"Stok Grubu","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"id_stock_groups","options":_vm.stockGroups,"label":"title","reduce":function (item) { return item.id; },"placeholder":"Seçiniz"},model:{value:(_vm.dataItem.id_stock_groups),callback:function ($$v) {_vm.$set(_vm.dataItem, "id_stock_groups", $$v)},expression:"dataItem.id_stock_groups"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Stok Kartı","label-for":"id_stocks"}},[_c('validation-provider',{attrs:{"name":"Stok Kartı","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"id_stocks","options":_vm.stocks,"label":"title","reduce":function (item) { return item.id; },"placeholder":"Seçiniz"},model:{value:(_vm.dataItem.id_stocks),callback:function ($$v) {_vm.$set(_vm.dataItem, "id_stocks", $$v)},expression:"dataItem.id_stocks"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"En Düşük Kullanılan Miktar","label-for":"min_amount"}},[_c('validation-provider',{attrs:{"name":"En Düşük Kullanılan Miktar","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":_vm.stockData.units}},[_c('cleave',{staticClass:"form-control",attrs:{"id":"min_amount","raw":true,"options":_vm.options,"placeholder":"Giriniz"},model:{value:(_vm.dataItem.min_amount),callback:function ($$v) {_vm.$set(_vm.dataItem, "min_amount", $$v)},expression:"dataItem.min_amount"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"En Yüksek Kullanılan Miktar","label-for":"max_amount"}},[_c('validation-provider',{attrs:{"name":"En Yüksek Kullanılan Miktar","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":_vm.stockData.units}},[_c('cleave',{staticClass:"form-control",attrs:{"id":"max_amount","raw":true,"options":_vm.options,"placeholder":"Giriniz"},model:{value:(_vm.dataItem.max_amount),callback:function ($$v) {_vm.$set(_vm.dataItem, "max_amount", $$v)},expression:"dataItem.max_amount"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Notlar","label-for":"notes"}},[_c('b-form-textarea',{attrs:{"id":"notes","placeholder":"Giriniz"},model:{value:(_vm.dataItem.notes),callback:function ($$v) {_vm.$set(_vm.dataItem, "notes", $$v)},expression:"dataItem.notes"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }